<template>
  <div class="header">
    <img :src="headerDatas.data.banner" class="town-pic"/>
    <img :src="headerDatas.data.title" class="title-img">
  </div>
  <div class="content">
    <NewsHeader :dataSource="dataSource.data"/>

    <div class="main-content" v-html="dataSource.data?.content" ></div>
  </div>

</template>

<script>
import {reactive, ref} from 'vue'
import {useRoute} from 'vue-router'
import api from '@/api'
import NewsHeader from '@/components/common/newsheader'
//文章详情
export default {
  name: 'articledetail',
  components:{
    NewsHeader
  },
  setup () {
    const route = useRoute()
    const dataSource = reactive({data:{}});

    const pageType = ref(route.query.pt ?? 0) // 0 小镇产业；1小镇动态；2小镇访谈
    const headerDatas = reactive({data: {banner: '/images/industry/banner.png',title:'/images/industry/title.png'}})
    const id = route.query.id ?? '';
    if (parseInt(pageType.value) === 1) {
      headerDatas.data = {banner: '/images/news/banner.png',title:'/images/news/title.png'}
    } else if (parseInt(pageType.value) === 2) {
      headerDatas.data = {banner: '/images/interview/banner.png',title:'/images/interview/title.png'}
    }

    const getData = async () =>{
       const getDetail =async ()=>{

         let res = await api.getDetail({id:id});

         dataSource.data = res;
       }

       getDetail();
    }

    getData();
    return {
      pageType,
      headerDatas,
      dataSource
    }
  }
}
</script>

<style lang="scss"  scoped>
.header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  .town-pic {
    width: 100%;
    height: 4.48rem;
  }

  .title-img {
    width: 6.4rem;
    height: 1.6rem;
    position: absolute;
  }
}
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.66rem 0;
  .main-content {
    word-break: break-all;

    width: 12rem;
    padding-right: 0.12rem;

    & /deep/ img {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>

<template>
  <div class="r-bottom-box">
    <h3 class="title">
      {{ dataSource.name || dataSource.title || dataSource.townName }}
    </h3>
    <div class="r-baseinfo-box">
      <div>
          <span class="text">
            <!--          townName 产业详情-->
            作者:{{ dataSource.userName || dataSource.townName }}
            </span>
        <span class="text" style="margin: 0 0.42rem">
          {{ getDate(dataSource.createDate) }}
            </span>
        <span>
              <img src="/images/activity/see.png" class="see-img">
          <!--          readCount 产业详情-->
              <span class="text">{{ dataSource.num || dataSource.readNums || dataSource.readCount || 0 }}</span>
            </span>
      </div>
      <div>
             <span class="text">
            分享：
            </span>
        <img src="/images/activity/weibo.png" class="share-img" style="margin: 0 0.08rem" @click="shareClick(0)"/>
        <img src="/images/activity/weixin.png" class="share-img" @click="shareClick(1)"/>
      </div>
    </div>
    <span class="line"></span>

  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'NewHeader',
  props: {
    dataSource: {
      type: Object,
      default: null
    },
    //分享的后半部分地址
    sUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    getDate (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    shareClick (type) {

      if (type == 0) {
        var shareUrl = 'http://service.weibo.com/share/share.php?'
        shareUrl += 'url=' + encodeURIComponent(window.location.href)
        //小镇故事页面需要带id
        if (encodeURIComponent(window.location.href).indexOf('story') >= 0) {
          shareUrl += '?id=' + encodeURIComponent(this.dataSource.id)
        }
        shareUrl += '&title=' + encodeURIComponent('')
        window.open(
            shareUrl, '_blank', 'height=100,width=400', 'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
        )

      } else {
        window.postMessage({'m_name': 'showQrCode', 'm_type': true}, '*',)

      }
    }
  }
}
</script>


<style lang="scss" scoped>
.r-bottom-box {
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;


}

.title {
  font-weight: 500;
  font-size: 0.28rem;
}

.r-baseinfo-box {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.share-img {
  width: 0.32rem;
  height: 0.32rem;
  cursor: pointer;
}

.line {
  background: #E1E1E1;
  width: 100%;
  height: 0.01rem;
  margin: 0.18rem 0;

}

.text {
  font-size: 0.16rem;
  color: #666666
}

.see-img {
  width: 0.18rem;
  height: 0.13rem;
  margin-right: 0.08rem;
}

</style>
